import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../feature-toggling'

const LazyPollieRaterInArticleBannerComponent = loadable(
    () => import('./PollieRaterInArticleBanner'),
)

export const LazyPollieRaterInArticleBanner = () => {
    return (
        <FeatureToggle
            feature="pollie-rater-drivers"
            on={() => <LazyPollieRaterInArticleBannerComponent />}
        />
    )
}
