import {
    regionalSectionLiveUrls,
    regionalSiteConfig,
    TheWestSection,
} from '@news-mono/common'
import { CampaignModifier, metrics } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType } from '@news-mono/web-common'
import { SwgPageOptions } from 'libs/component-library/src/subscribe-with-google/RegionalsSwgPromoPage/RegionalsSwgPromoPage'
import { layout } from '../../App.routing'

interface SwgLandingPageRouteProps {
    section: TheWestSection
    getAdTargeting: GetRouteAdTargeting
    options?: SwgPageOptions
}

interface MastheadMetaProps {
    title?: string
    description: string
    socialImage: string
    contentImage?: {
        x1: string
        x2: string
    }
}

function getMastheadMeta(
    section: Partial<TheWestSection> | CampaignModifier,
): MastheadMetaProps {
    switch (section) {
        case 'bunbury-herald':
            return {
                description: `The Bunbury Herald breaks local news, sport and publishes valuable community information. Enjoy access to the digital edition of every Tuesdays newspaper plus digital access to the highly sought-after real estate feature and motoring section keeping you up to date with the latest automotive trends.`,
                socialImage: require('./assets/BunburyHerald-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/BunburyHerald-devicecomp-min.png'),
                    x2: require('./assets/BunburyHerald-devicecomp-min@2x.png'),
                },
            }

        case 'narrogin-observer':
            return {
                description: `Having been a part of the region for more than 100 years, the Narrogin Observer is a strong and established community newspaper. The Narrogin Observer covers all aspects of news in the rural community, with sections encompassing farming, health and wellbeing, special features and sport. `,
                socialImage: require('./assets/NarroginObserver-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/NarroginObserver-devicecomp-min.png'),
                    x2: require('./assets/NarroginObserver-devicecomp-min@2x.png'),
                },
            }

        case 'south-western-times':
            return {
                description: `The South Western Times is the premier source of news and information for the South West region. Enjoy the latest in news, in-depth sport, a comprehensive entertainment guide, social pictures and community events. You can also access the digital edition of the paper including the biggest motoring and real estate features within the region.`,
                socialImage: require('./assets/SouthWesternTimes-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/SouthWesternTimes-devicecomp-min.png'),
                    x2: require('./assets/SouthWesternTimes-devicecomp-min@2x.png'),
                },
            }

        case 'albany-advertiser':
            return {
                description: `The Albany Advertiser has all the news from across the Great Southern. Albany’s own news source, enjoy access to all of your local, regional, sport and entertainment news. As part of your digital subscription, enjoy access to the digital edition of every Tuesday' and Thursday’s paper on your mobile or tablet and stay in touch when you are on the move.`,
                socialImage: require('./assets/AlbanyAdvertiser-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/AlbanyAdvertiser-devicecomp-min.png'),
                    x2: require('./assets/AlbanyAdvertiser-devicecomp-min@2x.png'),
                },
            }

        case 'kalgoorlie-miner':
            return {
                description: `The Kalgoorlie Miner is the only regional daily newspaper published in Western Australia and has one of the strongest readerships of any newspaper in Australia. Enjoy 24/7 digital access to breaking news, local news, sport, mining related stories, social pages and much more. You can also access the digital edition of the newspaper each day.`,
                socialImage: require('./assets/KalgoorlieMiner-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/KalgoorlieMiner-devicecomp-min.png'),
                    x2: require('./assets/KalgoorlieMiner-devicecomp-min@2x.png'),
                },
            }

        case 'harvey-waroona-reporter':
            return {
                description: `Covering the Shires of Harvey and Waroona, the Harvey-Waroona Reporter has been operating in the region for more than 30 years. Stay up to date with the latest news, sport, events, social pictures and enjoy access to the digital edition of every Tuesday’s newspaper. `,
                socialImage: require('./assets/HarveyWaroonaReporter-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/HarveyWaroonaReporter-devicecomp-min.png'),
                    x2: require('./assets/HarveyWaroonaReporter-devicecomp-min@2x.png'),
                },
            }

        case 'busselton-dunsborough-times':
            return {
                description: `The Busselton Dunsborough Times has a history dating back more than 100 years within the region. Its commitment to balanced editorial content and an easy reading style has seen the Times become Busselton and Dunsborough’s paper of choice, covering all aspects of local news. Stay up to date with 24/7 access to breaking news, sport, and opinion. Plus, enjoy the digital edition of the newspaper delivered fast to your mobile and tablet. `,
                socialImage: require('./assets/BusseltonDunsborougTimes-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/BusseltonDunsborougTimes-devicecomp-min.png'),
                    x2: require('./assets/BusseltonDunsborougTimes-devicecomp-min@2x.png'),
                },
            }

        case 'augusta-margaret-river-times':
            return {
                description: `The Augusta Margaret River Times was established in 2007 and continues to lead the way with quality editorial content. Stay up to date with 24/7 access to breaking news, sport, and opinion. Plus, enjoy the digital edition of every Friday’s newspaper delivered fast to your mobile and tablet. `,
                socialImage: require('./assets/BunburyHerald-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/AugustaMargaretRiverTimes-devicecomp-min.png'),
                    x2: require('./assets/AugustaMargaretRiverTimes-devicecomp-min@2x.png'),
                },
            }

        case 'manjimup-bridgetown-times':
            return {
                description: `The Manjimup Bridgetown Times is the only local newspaper serving the picturesque Southern Forests region. Stay up to date with 24/7 access to breaking news, sport, opinion, and community events. Plus, enjoy the digital edition of every Wednesday’s newspaper delivered fast to your mobile and tablet.`,
                socialImage: require('./assets/ManjimupBridgetownTimes-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/ManjimupBridgetownTimes-devicecomp-min.png'),
                    x2: require('./assets/ManjimupBridgetownTimes-devicecomp-min@2x.png'),
                },
            }
        case 'great-southern-herald':
            return {
                description: `The Great Southern Herald has been the journal of record in the heart of the Great Southern since 1901 and continues to provide comprehensive coverage of community and regional news. Stay up to date with 24/7 access to breaking news, sport, opinion, and community events. Plus, enjoy the digital edition of the weekly newspaper delivered fast to your mobile and tablet.`,
                socialImage: require('./assets/GreatSouthernHerald-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/GreatSouthernHerald-devicecomp-min.png'),
                    x2: require('./assets/GreatSouthernHerald-devicecomp-min@2x.png'),
                },
            }
        case 'broome-advertiser':
            return {
                description: `Since 1992, The Broome Advertiser has been delivering the latest news and vital information to northern WA communities. Stay up to date with 24/7 online access to breaking local news, sport, lifestyle features, the popular fishing guide and more. Plus, enjoy the digital edition of the Broome Advertiser delivered fast to your mobile and tablet every Tuesday and Thursday.`,
                socialImage: require('./assets/BroomeAdvertiser-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/BroomeAdvertiser-devicecomp-min.png'),
                    x2: require('./assets/BroomeAdvertiser-devicecomp-min@2x.png'),
                },
            }
        case 'geraldton-guardian':
            return {
                description: `With a trusted 100-year history, The Geraldton Guardian continues to be the leading source of local news in the Mid-West WA region. Stay up to date with stories that matter most to you and your community with 24/7 online access to local news, sport, entertainment and opinion. Plus, enjoy the digital edition of The Geraldton Guardian delivered fast to your mobile and tablet every Tuesday and Thursday.`,
                socialImage: require('./assets/GeraldtonGuardian-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/GeraldtonGuardian-devicecomp-min.png'),
                    x2: require('./assets/GeraldtonGuardian-devicecomp-min@2x.png'),
                },
            }
        case 'the-kimberley-echo':
            return {
                description: `The Kimberley Echo, established in 1980, continues to be the leading newspaper in the East Kimberley region. Stay up to date with 24/7 online access to breaking regional news, sport updates and valuable community information that is important to you. Plus, enjoy the digital edition of every Thursday’s Kimberley Echo delivered fast to your mobile and tablet.`,
                socialImage: require('./assets/KimberleyEcho-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/KimberleyEcho-devicecomp-min.png'),
                    x2: require('./assets/KimberleyEcho-devicecomp-min@2x.png'),
                },
            }
        case 'midwest-times':
            return {
                description: `Since 1992, the Midwest Times has been a trusted source for providing local news to its community. Stay up to date with one of WA’s fastest growing regions, with 24/7 online access to breaking news, entertainment, sport, opinion, and a comic section for your enjoyment. Plus, enjoy the digital edition of the Midwest Times newspaper delivered fast to your mobile and tablet.`,
                socialImage: require('./assets/MidwestTimes-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/MidwestTimes-devicecomp-min.png'),
                    x2: require('./assets/MidwestTimes-devicecomp-min@2x.png'),
                },
            }
        case 'north-west-telegraph':
            return {
                description: `The North West Telegraph serves communities throughout the resource rich Pilbara region, keeping the local community well informed. Enjoy 24/7 online access to local news, sport, lifestyle, real estate, motoring and more. You can also access the digital edition of every Wednesday’s North West Telegraph newspaper, straight from your mobile or tablet.`,
                socialImage: require('./assets/NorthWestTelegraph-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/NorthWestTelegraph-devicecomp-min.png'),
                    x2: require('./assets/NorthWestTelegraph-devicecomp-min@2x.png'),
                },
            }
        case 'pilbara-news':
            return {
                description: `The Pilbara News brings you the latest news and information from across the Pilbara region. Stay up to date with 24/7 online access to local news, sport, motoring and a comprehensive real estate section. Plus, enjoy the digital edition of every Wednesday’s Pilbara News newspaper delivered fast to your mobile and tablet.`,
                socialImage: require('./assets/PilbaraNews-socshare-min.jpg'),
                contentImage: {
                    x1: require('./assets/PilbaraNews-devicecomp-min.png'),
                    x2: require('./assets/PilbaraNews-devicecomp-min@2x.png'),
                },
            }
        case 'teachers-offer':
            return {
                title: 'FREE digital subscription for 12 months',
                description: `The West Australian is proud to offer you this exclusive offer of a FREE 12-month digital subscription thanks to our partnership with Google.\n\nAll you have to do to enjoy 24/7 in depth coverage of breaking news, sport, business, politics, entertainment and more is to provide your TRBWA number (Teacher Registration Number) and Subscribe with Google.`,
                socialImage: '',
                contentImage: {
                    x1: require('./assets/The-West-Subscription_devicecomp_min.png'),
                    x2: require('./assets/The-West-Subscription_devicecomp_min@2x.png'),
                },
            }
        case 'university-offer':
            return {
                title: 'Exclusive offer for all Curtin University students',
                description: `Have you been scouring Google for the news or information that matters to you? \n\nAs a Curtin University student, you can access business, politics, entertainment and sport news straight on your phone for no more than $1 a month.\n\nAll you have to do is Subscribe to Google and you get over $350 worth of value.`,
                socialImage: '',
                contentImage: {
                    x1: require('./assets/Uni-Student-Product-Shot_1148x934-min.png'),
                    x2: require('./assets/Uni-Student-Product-Shot_1148x934-min.png'),
                },
            }

        default:
            return {
                title: 'News delivered directly to your device',
                description: `As a digital subscriber to The West Australian you can access the latest in local, national and international breaking news delivered straight to your device. Enjoy in depth coverage across politics, business, sport, lifestyle and more from as little as $8 per week.\n\nYou can now Subscribe with Google, making it easy to manage your subscription and payment using your Google Account.`,
                socialImage: '',
                contentImage: {
                    x1: require('./assets/The-West-Subscription_devicecomp_min.png'),
                    x2: require('./assets/The-West-Subscription_devicecomp_min@2x.png'),
                },
            }
    }
}

function getProductId(section: TheWestSection) {
    if (section === 'default' || section === 'genwest') {
        return 'thewest.com.au'
    }

    const hostname = regionalSectionLiveUrls(section)

    return hostname.replace('https://www.', '')
}

export const getSwgLandingPage = ({
    section,
    getAdTargeting,
    options,
}: SwgLandingPageRouteProps): PageType<TheWestSection> => {
    const { modifier, heading, noIndex } = options || {}
    const { title, socialImage, description, contentImage } = getMastheadMeta(
        modifier || section,
    )
    const isRegionalSection = section !== 'default' && section !== 'genwest'

    return {
        kind: 'page',
        heading:
            heading ||
            `Subscribe with Google for $8 per week${
                isRegionalSection
                    ? ' to ' + regionalSiteConfig[section].title
                    : ''
            }`,
        hideHeading: true,
        pageType: 'static',
        adTargeting: getAdTargeting('home', section, 'our-papers'),
        pageMeta: {
            meta: noIndex
                ? [{ property: 'robots', content: 'noindex' }]
                : [
                      {
                          property: 'og:image',
                          content: socialImage,
                      },
                      {
                          name: 'twitter:image',
                          content: socialImage,
                      },
                      {
                          property: 'og:image:height',
                          content: '630',
                      },
                      {
                          property: 'og:image:width',
                          content: '1200',
                      },
                      {
                          property: 'og:description',
                          content: description,
                      },
                      {
                          name: 'description',
                          content: description,
                      },
                      {
                          property: 'twitter:description',
                          content: description,
                      },
                  ],
            ldJson: [
                {
                    isPartOf: {
                        '@type': ['CreativeWork', 'Product'],
                        name: isRegionalSection
                            ? regionalSiteConfig[section].title
                            : 'The West Australian',
                        productID: `${getProductId(section)}:everyday_digital`,
                    },
                    isAccessibleForFree: true,
                },
            ],
        },
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    hasBackgroundFill: true,
                    stretchSelf: true,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalGutters: ['md', 'md'],
                                breakpoints: {
                                    sm: {},
                                    lg: {},
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'regionals-swg-promo-page',
                                        props: {
                                            title,
                                            description,
                                            image: contentImage,
                                            productId: getProductId(section),
                                            options,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
