import { ArticlePublication } from '@news-mono/web-common'
import React from 'react'
import { LazyPollieRaterInArticleBanner } from '../../../banners/PollieRaterInArticleBanner/PollieRaterInArticleBanner.lazy'
import {
    InlineContentTypes,
    InlinePollieRaterBanner,
} from '../SharedPublication.routing'

export interface getInlinePositionedPollieRaterProps {
    publication: ArticlePublication
    inlinePublicationContent: InlineContentTypes[]
}

export function isInlineCallToAction(
    content: InlineContentTypes,
): content is InlinePollieRaterBanner {
    return content.kind === 'inline-pollie-rater-banner'
}

export function getInlinePositionedPollieRaterCta({
    publication,
    inlinePublicationContent,
}: getInlinePositionedPollieRaterProps) {
    if (publication.kind !== 'article') {
        return []
    }

    const validTopics = [
        'politics/federal-politics',
        'politics/federal-election',
    ]

    if (
        !validTopics.includes(publication.primaryTopic.id) &&
        !publication.secondaryTopics.find((topic) =>
            validTopics.includes(topic.id),
        )
    ) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineCallToAction)
        .map((content: InlinePollieRaterBanner) => {
            return {
                position: content.insertAfter,
                element: <LazyPollieRaterInArticleBanner />,
            }
        })
}
