import { ConfigurationContext } from '@news-mono/web-common'
import { PollieRaterData } from '@west-australian-newspapers/contentful-retrieval-utilities'
import React, { useContext, useEffect, useState } from 'react'
import { PollieRaterFilter } from '../PollieRaterFilter'
import { PollieRaterListing } from '../PollieRaterListing'
import {
    getParties,
    getPoliticians,
    mapScoresToPoliticians,
    Party,
    PoliticianPoll,
    PollScore,
    useGetUserPollData,
    usePollieRaterData,
} from './helpers'

export interface PollieRaterProps {
    onEvent: (event: any) => void
}

export const PollieRater: React.FC<PollieRaterProps> = (
    props: PollieRaterProps,
) => {
    const [sortedPoliticians, setSortedPoliticians] = useState<
        Array<PoliticianPoll>
    >([])
    const [currentParties, setCurrentParties] = useState<Array<Party>>([])
    const [selectedParty, setSelectedParty] = useState('Liberal')

    const [poliRaterError, setPoliRaterError] = useState(false)

    const config = useContext(ConfigurationContext)

    const result = usePollieRaterData(config.contentfulStaticSiteEndpoint)
    const userPollData = useGetUserPollData(config.userPollServiceEndpoint)

    const {
        isLoading,
        isError,
        data,
    }: {
        isLoading: boolean
        isError: boolean
        data: PollieRaterData | undefined
    } = result

    const {
        data: pollScores,
    }: {
        isLoading: boolean
        isError: boolean
        data: PollScore[] | undefined
    } = userPollData

    useEffect(() => {
        // Set poliRaterError equal to the value of isError
        setPoliRaterError(isError)
        if (!isLoading && !isError && data) {
            const parties = getParties(data)
            setCurrentParties(parties)

            const party = parties.find((party) => party.name === selectedParty)
            const politicians = party ? getPoliticians(party, data) : undefined

            if (politicians) {
                setSortedPoliticians(
                    mapScoresToPoliticians(politicians, pollScores),
                )
            }
        }
    }, [data, isLoading, isError, selectedParty, pollScores])

    return (
        <React.Fragment>
            <PollieRaterFilter
                parties={currentParties}
                selectedParty={selectedParty}
                setSelected={setSelectedParty}
            />
            <PollieRaterListing
                isError={poliRaterError}
                isLoading={isLoading}
                politicians={sortedPoliticians}
                refetch={result.refetch}
                contentfulEndpoint={config.contentfulStaticSiteEndpoint}
                refetchPollRating={userPollData.refetch}
                onEvent={props.onEvent}
            />
        </React.Fragment>
    )
}
export default PollieRater
