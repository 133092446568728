import {
    AdState,
    AllEvents,
    TheWestLiveState,
    TogglesReduxState,
} from '@news-mono/web-common'
import { Logger } from 'typescript-log'
import { getInlinePositionedAds } from '../../../templates/Publication/lib/get-inline-positioned-ads'
import { getInlinePositionedImageLink } from '../../../templates/Publication/lib/get-inline-positioned-imagelink'
import { getInlinePositionedPointer } from '../../../templates/Publication/lib/get-inline-positioned-pointer'
import { getInlinePositionedSportCallToAction } from '../../../templates/Publication/lib/get-inline-positioned-sport-call-to-action'
import { getInlinePositionedSportsHubCta } from '../../../templates/Publication/lib/get-inline-positioned-sports-hub-cta'
import { getInlinePositionedWestLiveBanner } from '../../../templates/Publication/lib/get-inline-positioned-west-live-banner'
import {
    MetaTypes,
    PublicationTypes,
} from '../../../templates/Publication/SharedPublication.routing'
import { getInlinedPositionedGoMatildasBanner } from '../lib/get-inline-positioned-go-matildas-banner'
import { getInlinedPositionedCourtInTheActBanner } from '../lib/get-inline-positioned-court-in-the-act-banner'
import { getInlinedPositionedMatchCentreBanner } from '../lib/get-inline-positioned-match-centre-banner'
import { getInlinedPositionedQueenTributeBanner } from '../lib/get-inline-positioned-queen-tribute-banner'
import { getInlinedReadersSurveyBanner } from '../lib/get-inline-positioned-readers-survey-banner'
import { getInlinePositionedTaboolaAmp } from '../lib/get-inline-positioned-taboola.amp'
import { getInlinedPositionedVanishingCousinsBanner } from '../lib/get-inline-positioned-vanishing-cousins-banner'
import { getInlinePositionedTheGameBanner } from '../lib/get-inline-positioned-the-game-banner'
import { getInlinedPositionedNightlyPromoBanner } from '../lib/get-inline-positioned-nightly-promo-banner'
import { getInlinePositionedOlympicsMedalWidget } from '../lib/get-inline-positioned-olympics-widget'
import { getInlinePositionedPollieRaterCta } from '../lib/get-inline-positioned-pollie-rater-banner'
import { getInlinePositionedFederalElectionCta } from '../lib/get-inline-positioned-federal-election-banner'

export function getWestInlinePositionedContent(props: {
    adState: AdState
    publication: PublicationTypes
    meta: MetaTypes
    log: Logger
    toggles: TogglesReduxState
    onEvent: (event: AllEvents) => void
    westLiveState?: TheWestLiveState
    section?: string
}) {
    if (!props.publication) {
        return []
    }

    // inline content should not be rendered on gallery publications or events (live blogs)
    if (
        props.publication.kind === 'gallery' ||
        props.publication.kind === 'event' ||
        props.meta.kind === 'gallery' ||
        !props.meta.inlinePublicationContent
    ) {
        return []
    }

    return [
        ...getInlinePositionedAds({
            adState: props.adState,
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedPointer({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinePositionedImageLink({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinePositionedSportCallToAction({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedWestLiveBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinePositionedSportsHubCta({
            inlinePublicationContent: props.meta.inlinePublicationContent,
            publication: props.publication,
        }),
        ...getInlinePositionedTaboolaAmp({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinedPositionedQueenTributeBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinedPositionedGoMatildasBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinedReadersSurveyBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
            section: props.meta.section,
        }),
        ...getInlinedPositionedMatchCentreBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinedPositionedCourtInTheActBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinedPositionedVanishingCousinsBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedTheGameBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinedPositionedNightlyPromoBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedOlympicsMedalWidget({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedPollieRaterCta({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
        }),
        ...getInlinePositionedFederalElectionCta({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
    ]
}
