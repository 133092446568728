import { AppState, useFeature } from '@news-mono/web-common'
import React from 'react'
import { QueryObserverResult, RefetchOptions } from 'react-query'
import { useSelector } from 'react-redux'
import { PoliticianPoll } from '../PollieRater/helpers/types'
import { PollieRaterForm, PollieRaterSignup } from './Components'
import {
    StyledErrorBlock,
    StyledErrorButton,
    StyledErrorText,
    StyledPoliRaterErrorContainer,
    StyledPolliDescription,
    StyledPollieImage,
    StyledPollieImageContainer,
    StyledPollieRaterContainer,
    StyledPollieTextContainer,
    StyledRatingLabel,
    StyledRatingsContainer,
    StyledRatingScore,
    StyledTextBlock,
} from './PollieRaterListing.styled'

export interface PollieRaterListingProps {
    isError: boolean
    isLoading: boolean // These are used to trigger the error view if required.
    politicians: Array<PoliticianPoll>
    contentfulEndpoint: string
    refetch: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<any, unknown>>
    refetchPollRating: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<any, unknown>>
    isLoggedInOverride?: boolean
    onEvent: (event: any) => void
}

export const PollieRaterListing: React.FC<PollieRaterListingProps> = ({
    contentfulEndpoint,
    isError,
    isLoading,
    politicians,
    refetch,
    refetchPollRating,
    isLoggedInOverride,
    onEvent,
}) => {
    const showPollieRaterListing = useFeature(
        'federal-election-2022-pollie-rater',
        false,
    )

    const { isLoggedIn } = useSelector(
        (state: AppState) => state.authentication,
    )

    if (!showPollieRaterListing) {
        return null
    }

    if (!isLoading && isError) {
        return (
            <React.Fragment>
                <StyledPoliRaterErrorContainer>
                    <StyledErrorBlock>
                        <StyledTextBlock>
                            <StyledErrorText>
                                Something went wrong!
                                <br />
                                Try reloading the Pollie Rater.
                            </StyledErrorText>
                        </StyledTextBlock>
                        <StyledErrorButton onClick={() => refetch()}>
                            Reload
                        </StyledErrorButton>
                    </StyledErrorBlock>
                </StyledPoliRaterErrorContainer>
            </React.Fragment>
        )
    }

    if (!isLoading && politicians && politicians.length > 0) {
        return (
            <React.Fragment>
                {politicians.map((politician) => (
                    <StyledPollieRaterContainer key={politician.id}>
                        <StyledPollieImageContainer>
                            <StyledPollieImage
                                src={`${contentfulEndpoint}/pollie-rater/${politician.profilePicture}`}
                                alt=""
                            />
                        </StyledPollieImageContainer>
                        <StyledPollieTextContainer>
                            <div>
                                <h2>{politician.name}</h2>
                                <h3>
                                    {politician.party} -{' '}
                                    <span>{politician.position}</span>
                                </h3>
                                <StyledPolliDescription>
                                    {politician.description}
                                </StyledPolliDescription>
                            </div>
                            {isLoggedIn || isLoggedInOverride ? (
                                <PollieRaterForm
                                    userScore={
                                        politician.userScore
                                            ? politician.userScore
                                            : 5
                                    }
                                    politician={politician}
                                    refetchPollRating={() =>
                                        refetchPollRating()
                                    }
                                    onEvent={onEvent}
                                />
                            ) : (
                                <PollieRaterSignup
                                    politicianName={politician.name}
                                />
                            )}
                        </StyledPollieTextContainer>
                        <StyledRatingsContainer>
                            <div>
                                <StyledRatingScore>
                                    <em>{politician.expertRating}</em>/10
                                </StyledRatingScore>{' '}
                                <StyledRatingLabel>
                                    Expert Rating
                                </StyledRatingLabel>
                            </div>
                            <div>
                                <StyledRatingScore>
                                    <em>
                                        {politician.userScore
                                            ? politician.averageScore
                                            : '?'}
                                    </em>
                                    /10
                                </StyledRatingScore>{' '}
                                <StyledRatingLabel>
                                    Average Voter Rating
                                </StyledRatingLabel>
                            </div>
                        </StyledRatingsContainer>
                    </StyledPollieRaterContainer>
                ))}
            </React.Fragment>
        )
    }

    return null
}
